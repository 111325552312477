import logo from './logo.svg';
import './App.css';

function App() {
  var vCardsJS = require('@abumalick/vcards-js');
  
  //create a new vCard
  var vCard = vCardsJS();
  
  //set properties
  vCard.firstName = 'Melvin';
  vCard.lastName = 'Ong';
  vCard.organization = 'Karuna Sarawak';
  vCard.cellPhone = '0194789319';
  vCard.title = 'Chief Executive Officer';
  vCard.note = 'Hi! I love choc!';
  
  //save to file
  const a = document.createElement('a') // Create "a" element
  const blob = new Blob([vCard.getFormattedString()], {type: "text/vcard"}) // Create a blob (file-like object)
  const url = URL.createObjectURL(blob) // Create an object URL from blob
  a.setAttribute('href', url) // Set "a" element link
  a.setAttribute('download', "contact.vcf") // Set download filename
  a.click() // Start downloading  
  return (
    <div className="App">
      <header className="App-header">
        <p>
          Warmest greetings from
        </p>
        <a
          className="App-link"
          href="https://karunasarawak.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          Karuna Sarawak
        </a>
      </header>
    </div>
  );
}

export default App;
